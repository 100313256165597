<template>
  <div>
    <!-- ======= Body ======= -->
    <!-- ======= Hero Section ======= -->
    <!-- ======= Featured Services Section ======= -->
    <section id="featured-services" class="featured-services">
      <div class="container">
        <div class="row">
          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          >
            <div class="icon-box">
              <div class="icon"><i class="fas fa-heartbeat"></i></div>
              <h4 class="title"><a href="">Efficacité et rapidité</a></h4>

              <p class="description accord" style="margin-top: 40px">
                MedPay facilite et accélère les processus associés à la gestion
                des dossiers médicaux et des recettes. Les documents médicaux
                sont numérisés, stockés et accessibles électroniquement de
                manière simple, ce qui réduit les délais de traitement et
                améliore la productivité globale du système de santé.
              </p>
            </div>
          </div>

          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          >
            <div class="icon-box">
              <div class="icon"><i class="fas fa-pills"></i></div>
              <h4 class="title"><a href="">Réduction des coûts</a></h4>
              <p class="description accord okk">
                MedPay élimine la nécessité de manipuler des documents
                physiques, ce qui entraîne une diminution des coûts liés au
                stockage, à la gestion des archives et à la distribution papier.
                De plus, les processus automatisés permettent de réduire les
                erreurs humaines et les retards, ce qui peut également conduire
                à des économies financières.
              </p>
            </div>
          </div>

          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          >
            <div class="icon-box">
              <div class="icon"><i class="fas fa-thermometer"></i></div>
              <h4 class="title"><a href="">Sécurité des données</a></h4>
              <p class="description accord">
                MedPay propose des mesures de sécurité renforcées pour les
                données sensibles. Les systèmes intègrent des mécanismes de
                contrôle d'accès, de cryptage et de sauvegardes régulières afin
                de réduire les risques de perte ou de vol de données.
              </p>
            </div>
          </div>

          <div
            class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
          >
            <div class="icon-box">
              <div class="icon"><i class="fas fa-dna"></i></div>
              <h4 class="title">
                <a href="">Accessibilité et disponibilité</a>
              </h4>
              <p class="description accord">
                Les dossiers médicaux dématérialisés facilitent la consultation
                et le partage entre les divers acteurs de la santé, tels que les
                médecins, les hôpitaux et les compagnies d'assurance. Cette
                démarche favorise une coordination améliorée des soins et réduit
                les redondances ainsi que les erreurs médicales.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Featured Services Section -->
    <section id="cta" class="cta">
      <div class="container">
        <div class="text-center">
          <h3>Besoin d'aide ? Rendez-vous dans nos centres de santé !</h3>
          <p>
            Obtenez les réponses à toutes vos questions médicales, accédez à des
            conseils éclairés et bénéficiez d'une expertise de confiance. Prenez
            le pouvoir sur votre bien-être grâce à notre plateforme !
          </p>
        </div>
      </div>
    </section>
    <!-- End Cta Section -->

    <!-- ======= About Us Section ======= -->
    <section id="about" class="about">
      <div class="container">
        <div class="section-title">
          <h2>A propos</h2>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <img src="../asset/img/about.jpg" class="img-fluid" alt="" />
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 content">
            <h3>MEDPAY</h3>

            <p style="text-align: justify">
              MedPay est une plateforme spécialement développée pour les
              Établissements Publics Hospitaliers du Bénin, qui vise à
              dématérialiser la gestion des caisses sanitaires. <br />
              <br />
              Son objectif est de simplifier et d'optimiser la gestion des
              opérations financières liées aux soins de santé. <br />
              <br />
              Elle permet aux Établissements Publics Hospitaliers de gérer
              électroniquement toutes les transactions financières liées aux
              soins de santé, telles que la facturation, la gestion des
              paiements, la gestion des patients, la gestion de la carte
              sanitaire, la gestion des actes médicaux et la gestion de la
              trésorerie.
            </p>

            <p style="font-weight: bold; color: rgb(7, 92, 78)">
              MedPay représente la solution parfaite pour vos besoins en matière
              de soins de santé !
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Us Section -->

    <!-- ======= Counts Section ======= -->
    <section id="counts" class="counts">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="fas fa-user-md"></i>
              <span class="purecounter">1000</span>

              <p>
                <strong>Les centres de santé</strong> sont présents dans nos
                villes, villages et quartiers urbains. villages et quartier de
                villes
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="far fa-hospital"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="12"
                data-purecounter-duration="1"
                class="purecounter"
                >12</span
              >
              <p>
                <strong>Departements:</strong> Nos centres de santé sont
                répartis dans les douze départements du Bénin.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="fas fa-flask"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="77"
                data-purecounter-duration="1"
                class="purecounter"
                >77</span
              >
              <p>
                <strong>Communes:</strong> Nos centres de santé sont répartis
                dans les soixante-dix-sept communes du Bénin.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
            <div class="count-box">
              <i class="fas fa-award"></i>
              <span
                data-purecounter-start="0"
                data-purecounter-end="546"
                data-purecounter-duration="1"
                class="purecounter"
                >546</span
              >
              <p>
                <strong>Arrondissements:</strong> Nos centres de santé sont
                repartis dans les différents arrondissements BENIN.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Counts Section -->

    <!-- ======= Features Section ======= -->
    <section id="features" class="features">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 order-2 order-lg-1">
            <div class="icon-box mt-5 mt-lg-0">
              <i class="bx bx-receipt"></i>
              <h4>Accessibilité améliorée</h4>
              <p>
                MedPay offre aux patients une accessibilité aisée aux services
                de caisse sanitaire, quel que soit l'endroit où ils se trouvent,
                tant qu'ils disposent d'une connexion Internet.
              </p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-cube-alt"></i>
              <h4>Communication plus rapide</h4>
              <p>
                Les patients peuvent entrer en contact avec la caisse sanitaire
                via notre plateforme en ligne, ce qui permet d'obtenir des
                réponses plus rapides à leurs interrogations et inquiétudes.
              </p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-images"></i>
              <h4>Assurance</h4>
              <p>MedPay simplifie les procédures relatives à l'assurance.</p>
            </div>
            <div class="icon-box mt-5">
              <i class="bx bx-shield"></i>
              <h4>Gestion du risque</h4>
              <p>
                L'assurance joue un rôle crucial dans la gestion des risques en
                offrant aux individus et aux entreprises la possibilité de se
                protéger contre divers risques tels que la santé, les biens, la
                responsabilité civile, les catastrophes naturelles, et bien
                d'autres.
              </p>
            </div>
          </div>
          <div
            class="image col-lg-6 order-1 order-lg-2"
            id="featuredImage"
          ></div>
        </div>
      </div>
    </section>
    <!-- End Features Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services services">
      <div class="container">
        <div class="section-title">
          <h2>Services</h2>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fas fa-heartbeat"></i></div>
            <h4 class="title"><a href="">Gestion des Patients</a></h4>
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fas fa-pills"></i></div>
            <h4 class="title">
              <a href="">Intégration de la carte sanitaire</a>
            </h4>
            <!-- <p class="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p> -->
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fas fa-hospital-user"></i></div>
            <h4 class="title"><a href="">Gestion des actes médicaux</a></h4>
            <!-- <p class="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p> -->
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fas fa-dna"></i></div>
            <h4 class="title"><a href="">Gestion des Paiements</a></h4>
            <!-- <p class="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p> -->
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fas fa-wheelchair"></i></div>
            <h4 class="title"><a href="">Gestion des Factures</a></h4>
            <!-- <p class="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p> -->
          </div>
          <div class="col-lg-4 col-md-6 icon-box" id="deg">
            <div class="icon"><i class="fas fa-notes-medical"></i></div>
            <h4 class="title"><a href="">Gestion de la trésorerie</a></h4>
            <!-- <p class="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p> -->
          </div>
        </div>
      </div>
    </section>
    <!-- End Services Section -->

    <!-- ======= Departments Section ======= -->
    <section id="departments" class="departments">
      <div class="container">
        <div class="section-title">
          <h2>Nos SOLUTIONS</h2>
        </div>

        <div class="row">
          <div class="col-lg-4 mb-5 mb-lg-0">
            <ul class="nav nav-tabs flex-column">
              <li class="nav-item">
                <a
                  class="nav-link active show"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-1"
                >
                  <h4>Gestion des Patients</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-2"
                >
                  <h4>Carte sanitaire</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-3"
                >
                  <h4>Gestion des actes médicaux</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-4"
                >
                  <h4>Paiements et Facturations</h4>
                </a>
              </li>
              <li class="nav-item mt-2">
                <a
                  class="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#tab-5"
                >
                  <h4>Gestion de la trésorerie</h4>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-8">
            <div class="tab-content">
              <div class="tab-pane active show" id="tab-1">
                <h3>Gestion des Patients</h3>
                <img src="../images/aide1.jpg" alt="" class="img-fluid" />
                <p>
                  Notre solution permet une gestion complète des informations
                  des patients, incluant leur nom, prénom, âge, profession,
                  numéro de téléphone, et bien d'autres.<br />
                  <br />
                  <br />
                  Le module de gestion des patients permet également de mettre
                  en évidence la base de données de tous les patients au sein de
                  chaque établissement hospitalier.
                </p>
              </div>
              <div class="tab-pane" id="tab-2">
                <h3>Carte sanitaire</h3>
                <img src="../images/carte.jpg" alt="" class="img-fluid" />
                <p>
                  Nous vous fournissons de manière précise la localisation
                  géographique des établissements publics hospitaliers à
                  l'échelle nationale et régionale. <br />
                  <br />
                  Ce module permettra également la création d'une base de
                  données regroupant tous les centres hospitaliers du Bénin.
                </p>
              </div>
              <div class="tab-pane" id="tab-3">
                <h3>Gestion des actes médicaux</h3>
                <img src="../images/actes.jpg" alt="" class="img-fluid" />
                <p>
                  Notre solution garantit la gestion de divers types de services
                  médicaux facturés au niveau de la caisse <br />
                  <br />
                  Ce module facilite la classification des actes médicaux en
                  fonction des demandes des clients, dans le but d'améliorer la
                  qualité des services de santé publics.
                </p>
              </div>
              <div class="tab-pane" id="tab-4">
                <h3>Gestion des Paiements et Facturations</h3>
                <img src="../images/payement.jpg" alt="" class="img-fluid" />
                <p>
                  MedPay garantit la traçabilité de tous les paiements des actes
                  médicaux effectués par les clients. <br />
                  <br />Ce module assure une sécurité renforcée, une
                  augmentation des revenus et une économie de temps considérable
                  dans le processus de facturation.
                </p>
              </div>
              <div class="tab-pane" id="tab-5">
                <h3>Gestion de la trésorerie</h3>
                <img src="../images/tresor.jpg" alt="" class="img-fluid" />
                <p>
                  Le système prend en charge les flux financiers d'entrée et de
                  sortie, garantissant une gestion optimale de la trésorerie. Il
                  offre une gestion sécurisée ainsi qu'un suivi des
                  encaissements et des décaissements. <br />
                  <br />Ce module permettra de surveiller en temps réel
                  l'évolution des finances, d'anticiper la gestion des risques
                  financiers et de prendre les mesures nécessaires pour
                  surmonter d'éventuels déficits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Departments Section -->
    <!-- ======= Actor Section ======= -->
    <!-- ======= Actor Section ======= -->
    <section id="actor" class="testimonials">
      <div class="container">
        <div class="section-title">
          <h2>ACTEURS DE MEDPAY</h2>
        </div>

        <div class="testimonials-slider swiper">
          <div class="row">
            <div class="col-lg-3 col-12">
              <div class="">
                <div class="testimonial-item">
                  <h6 class="text-center">
                    <strong>CENTRE DE SANTE</strong>
                  </h6>
                  <p class="hover">
                    Un centre de santé désigne une infrastructure de soins de
                    santé qui propose une variété de services médicaux,
                    généralement de nature primaire ou ambulatoire, afin de
                    répondre aux besoins de la communauté locale.
                  </p>
                  <!-- <img src="images/hopital.jpg" class="testimonial-img" alt=""> -->
                </div>
              </div>
              <!-- End testimonial item -->
            </div>
            <div class="col-lg-3 col-12">
              <div class="">
                <div class="testimonial-item">
                  <h6 class="text-center">
                    <strong>PERSONNELS MEDICAUX</strong>
                  </h6>
                  <p class="hover">
                    Le personnel médical englobe tous les praticiens de la santé
                    qui exercent leur métier dans des établissements de soins
                    tels que les hôpitaux, les cliniques, les cabinets médicaux
                    et d'autres structures similaires.
                  </p>
                  <!-- <img src="images/med.avif" class="testimonial-img" alt=""> -->
                </div>
              </div>
              <!-- End testimonial item -->
            </div>
            <div class="col-lg-3 col-12">
              <div class="">
                <div class="testimonial-item">
                  <h6 class="text-center">
                    <strong>PATIENTS</strong>
                  </h6>
                  <!-- <h3>PATIENTS</h3> -->
                  <p class="hover">
                    Un patient hospitalisé est une personne qui bénéficie de
                    soins médicaux et hospitaliers en raison d'une maladie,
                    d'une blessure ou d'une condition de santé.
                  </p>
                  <!-- <img src="images/patient.PNG" class="testimonial-img" alt=""> -->
                </div>
              </div>
              <!-- End testimonial item -->
            </div>
            <div class="col-lg-3 col-12">
              <div class="">
                <div class="testimonial-item">
                  <h6 class="text-center">
                    <strong>MINISTÈRE DE LA SANTÉ</strong>
                  </h6>
                  <p class="hover">
                    Le ministère de la santé au Bénin est une entité
                    gouvernementale responsable de la planification, de la
                    réglementation, de la supervision et de la promotion de la
                    santé et des services de santé dans le pays.
                  </p>
                  <!-- <img src="images/MS.png" class="testimonial-img" alt=""> -->
                </div>
              </div>
              <!-- End testimonial item -->
            </div>
          </div>
          <!-- End testimonial item -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <!-- End Testimonials Section -->

    <!-- ======= Contact Section ======= -->
    <section id="contact" class="contact">
      <div class="container">
        <div class="section-title">
          <h2>Contact</h2>
          <p>
            Pour nous contacter, veuillez nous envoyer un message par email ou
            utiliser les numéros de téléphone indiqués ci-dessous pour nous
            appeler.
          </p>
        </div>
      </div>

      <!-- <div>
         <iframe id="iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63443.59310936481!2d2.422241972313055!3d6.36497637245072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sbj!4v1685731403629!5m2!1sfr!2sbj" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div> -->

      <div class="container">
        <div class="row mt-5">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-12">
                <div class="info-box">
                  <i class="bx bx-map"></i>
                  <h3>Notre Adresse</h3>
                  <p>
                    Sodjatinmé Rue 1684, 4e arrondissement de Cotonou, COTONOU,
                    BENIN
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box mt-4">
                  <i class="bx bx-envelope"></i>
                  <h3>Email</h3>
                  <p>info@example.com<br />contact@example.com</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box mt-4">
                  <i class="bx bx-phone-call"></i>
                  <h3>Appelez-nous</h3>
                  <p>+229 000000<br />+229 0000000</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              class="php-email-form"
            >
              <div class="row">
                <div class="col-md-6 form-group">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Votre Nom"
                    required=""
                  />
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Votre Email"
                    required=""
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Sujet"
                  required=""
                />
              </div>
              <div class="form-group mt-3">
                <textarea
                  class="form-control"
                  name="message"
                  rows="7"
                  placeholder="Message"
                  required=""
                ></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Chargement</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Votre message a été envoyé. Merci!
                </div>
              </div>
              <div class="text-center">
                <button type="submit">Envoyer le message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Section -->
    <p id="local">Localisation du Ministère de la Santé du BENIN</p>

    <div class="row">
      <iframe
        id="iframe"
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63443.59310936481!2d2.422241972313055!3d6.36497637245072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2sbj!4v1685731403629!5m2!1sfr!2sbj"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </div>
</template>

<script>
// Déplacez les importations JavaScript ici, à l'intérieur de la section <script>
import "../asset/vendor/purecounter/purecounter_vanilla.js";
import "../asset/vendor/aos/aos.js";
import "../asset/vendor/bootstrap/js/bootstrap.bundle.min.js";
import "../asset/vendor/glightbox/js/glightbox.min.js";
import "../asset/vendor/swiper/swiper-bundle.min.js";
import "../asset/vendor/php-email-form/validate.js";
//import "../asset/js/main.js";
export default {
  name: "FeaturedComponent",
  components: {},
  mounted() {
    /**
     * Testimonials slider
     */
    new swiper(".testimonials-slider", {
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },

        1200: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
    });

    /**
     * Clients Slider
     */
    new swiper(".gallery-slider", {
      speed: 400,
      loop: true,
      centeredSlides: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
      },
    });
  },
};
</script>

<style scoped>
section {
  padding: 60px 0;
  overflow: hidden;
}
#local {
  text-align: center;

  color: #053832;
}
#iframe {
  border: 0px;
  width: 100%;
  height: 500px;
}
#featuredImage {
  background-image: url("../asset/img/features.jpg");
}

.hover:hover {
  transition: 1.2s;
  background-color: #2ea096;
  color: white;
}
@media screen and (max-width: 1200px) {
  .testimonial-item {
    margin-bottom: 0px;
  }
}
@import "../asset/vendor/fontawesome-free/css/all.min.css";
@import "../asset/vendor/animate.css/animate.min.css";
@import "../asset/vendor/aos/aos.css";
@import "../asset/vendor/bootstrap-icons/bootstrap-icons.css";
@import "../asset/vendor/boxicons/css/boxicons.min.css";
@import "../asset/vendor/glightbox/css/glightbox.min.css";
@import "../asset/vendor/swiper/swiper-bundle.min.css";
@import "../asset/css/style.css";
</style>
