<template>
  <div>
    <footer id="footer">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="footer-info">
                <h3>MedPay</h3>
                <p>
                  Sodjatinmé Rue 1684, 4e arrondissement de Cotonou<br />
                  COTONOU, BENIN<br /><br />
                  <strong>Phone:</strong> +229 00000000<br />
                  <strong>Email:</strong> https://sante.gouv.bj/<br />
                </p>
                <div class="social-links mt-3">
                  <a href="https://twitter.com/Presidencebenin" class="twitter"
                    ><i class="bx bxl-twitter"></i
                  ></a>
                  <a
                    href="https://www.facebook.com/msantebenin/"
                    class="facebook"
                    ><i class="bx bxl-facebook"></i
                  ></a>

                  <!--<a href="#" class="google-plus"
                    ><i class="bx bxl-skype"></i
                  ></a>-->
                  <!--<a href="#" class="instagram"
                    ><i class="bx bxl-instagram"></i
                  ></a>-->

                  <a
                    href="https://www.linkedin.com/company/ministere-de-la-sante-du-benin/

"
                    class="linkedin"
                    ><i class="bx bxl-linkedin"></i
                  ></a>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 footer-links">
              <h4>Liens utiles</h4>
              <ul>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#hero">Accueil</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#about">A Propos</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i> <a href="#services">Services</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#departments">Nos Solution</a>
                </li>
                <li>
                  <i class="bx bx-chevron-right"></i>
                  <a href="#actor">Acteurs de MedPay</a>
                </li>
              </ul>
            </div>

            <!-- <div class="col-lg-3 col-md-6 footer-links">
              <h4>Nos Services</h4>
              <ul>
                <li>
                  <i class=""></i>
                  <p >Gestion des Patients</p>
                </li>
                <li>
                  <i class=""></i>
                  <p>Carte sanitaire</p>
                </li>
                <li>
                  <i class=""></i>
                  <p>Gestion des actes médicaux</p>
                </li>
                <li>
                  <i class=""></i>
                  <p>Paiements et Facturations</p>
                </li>
                <li>
                  <i class=""></i>
                  <p>Gestion de la trésorerie</p>
                </li>
              </ul>
            </div> -->

            <div class="col-lg-4 col-md-6 footer-newsletter">
              <h4>Newsletter</h4>
              <p></p>
              <form action="" method="post">
                <input type="email" name="email" /><input
                  type="submit"
                  value="Soumettre"
                />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="copyright">
          &copy; Copyright © 2023
          <a href="http://akasigroup.net/"
            ><strong><span>Akasi Group SARL</span></strong></a
          >
          Tous droits réservés | Politique de confidentialité | Terms et
          Conditions
        </div>
      </div>
    </footer>
    <!-- End Footer -->
  </div>
</template>

<script>
// Déplacez les importations JavaScript ici, à l'intérieur de la section <script>

export default {
  name: "FooterComponent",
  components: {},
};
</script>

<style>
@import "../asset/css/style.css";
</style>